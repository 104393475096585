<template lang='pug'>
	.car-information
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/carinformation.jpg')
				.number 404
				h1.title Are you lost?
				.dashes
					.dash(v-for='n in 5')
				.para.fs Seems like you have gotten lost along the way. You can always go to our front page.
				.section-one
					router-link.button(to='/')
						.text Go to homepage
						img.arrow(src='@/assets/icons/arrow-blue.svg')
</template>

<script>
export default {
	name: 'NotFoundPage',
	metaInfo: {
		title: 'Safe.is - not found page.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			}
		]
	},
	data: function () {
		return {

		};
	}
};
</script>

<style lang='less'>
	.car-information {
		@media screen and (max-width: 1220px) {
			margin-bottom: 0px;
		}
		margin-bottom: -96px;
		.header {
			height: 628px;
			max-height: 628px;
			background-image: url('~@/assets/jpg/carinfoheader.jpg');
			background-size: cover;
			background-position: 50% 100%;
			.header-image {
				display: none;
			}
			@media screen and (max-width: 1220px) {
				background-image: none;
				height: initial;
				max-height: initial;
				.header-image {
					margin-top: 48px;
					display: block;
					width: 100%;
					max-height: 440px;
					object-fit: cover;
					object-position: bottom;
				}
			}
			.content {
				position: relative;
				top: 0px;
				height: 100%;
				left: 0px;
				text-align: left;
				.number {
					font-family: Montserrat;
					font-style: normal;
					font-weight: 300;
					font-size: 32.9893px;
					line-height: 49px;
					letter-spacing: 1.31957px;
					color: #F9DB49;
					transform: translate(0, 125%);
				}
				.title {
					padding-top: 68px;
					@media screen and (max-width: 768px) {
						padding-top: 40px;
					}
					position: relative;
					max-width: 374px;
				}
				.para {
					padding-top: 38px;
					max-width: 374px;
				}
			}
		}
		.dashes {
			margin-top: 38px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
		.section-one {
			.button {
				display: grid;
				grid-template-columns: 80% 20%;
				height: 48px;
				width: 290px;
				background-color: #FFFFFF;
				box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
				border-left: 4px solid #F9DB49;
				margin-top: 77px;
				transition: .3s;
				.text {
					color: black;
					height: 30px;
					width: 180px;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 1.5px;
					line-height: 48px;
					padding-left: 32px;
				}
				.arrow {
					position: relative;
					top: 50%;
					transform: translate3d(0px, -50%, 0px);
					transition: .3s;
				}
				&:hover {
					transform: scale(1.03);
					border-left: 16px solid #F9DB49;
					width: 300px;
					box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.07);
					.arrow {
						transform: translate3d(5px, -50%, 0px);
					}
				}
			}
		}
	}
</style>
